import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import WebPerfexLogo from '../components/logo'
import { Link } from 'gatsby'


 
const MyWebPerfex = () => (
<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">

    <SEO title="my|WebPerfex" description="Welcome to the WebPerfex client portal login page. Access your account, track your projects, and communicate with our team. Login now for seamless collaboration."/>
    <div class="mx-auto sm:w-full sm:max-w-md">
    <Link to="/" className="flex" title={'WebPerfex'}>
              <div className="mx-auto" style={{ width: '200px' }}>
                <WebPerfexLogo />
              </div>
            </Link>
    <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
      Sign in to your account
    </h2>
    <p className="text-gray-600 text-center">WebPerfex Project Dashboard</p>
     
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form action="#" method="POST">
        <div>
          <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
            Client ID
          </label>
          <div class="mt-1 rounded-md shadow-sm">
            <input id="email" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
          </div>
        </div>

        <div class="mt-6">
          <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
            Password
          </label>
          <div class="mt-1 rounded-md shadow-sm">
            <input id="password" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
          </div>
        </div>

        <div class="mt-6 flex items-center justify-between">
          <div class="flex items-center">
            <input id="remember_me" type="checkbox" class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"/>
            <label for="remember_me" class="ml-2 block text-sm leading-5 text-gray-900">
              Remember me
            </label>
          </div>

          <div class="text-sm leading-5">
            <a href="#" class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
              Forgot your password?
            </a>
          </div>
        </div>

        <div class="mt-6">
          <span class="block w-full rounded-md shadow-sm">
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
              Sign in
            </button>
          </span>
        </div>
      </form> 
    </div>
  </div>
     
  </div>
)

export default MyWebPerfex
